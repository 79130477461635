const en = {
    1: "OCEAN Cloud",
    2: "View Data",
    3: "Projects",
    4: "More",
    5: "About the Site",
    6: "Terms and Policies",
    7: "Contact Us",
    8: "Submit Data",
    9: "Log In",
    10: "<tag>My</tag> Page",
    11: "Email Opt-out",
    12: "Ocean Knights Community",
    13: "Safety e-Report",
    14: "Ocean Cloud Introduction\n168, Gasan Digital 1-ro, Geumcheon-gu, Seoul, 1102 (Gasan-dong, Woorim Lions Valley)\nRepresentative number: 02-3442-7236 / Fax: 02-3442-7124 / Inquiry Mail: OceanCloud@devguru.co.kr\nDevguru / CEO: Song Ji-ho / Business Registration Number: 114-86-37116",
    15: "This is a required field.",
    16: "Please enter a valid email address.",
    17: "Search for the marine data you want.",
    18: "Status of Ocean Knights’ data collection",
    19: "Number of Collected Data",
    20: "Completed <tag>Projects</tag>",
    21: "Projects in Progress",
    22: "Investigators",
    23: "Survey Region",
    24: "Recent Data Entries",
    25: "To more accurately assess the marine conditions, we need more samples.\nCheck out the marine conditions through data directly registered by citizen scientists.",
    26: "Investigator",
    27: "Survey Date",
    28: "GROUP",
    29: "NEW",
    30: "View More Data +",
    31: "PROJECT",
    32: "You can check the marine conditions by theme through various projects.",
    33: "Data",
    34: "Search Data",
    35: "Search Criteria",
    36: "No relevant data found.",
    37: "My Data",
    38: "Group",
    39: "All Groups",
    40: "All Projects",
    41: "Region",
    42: "All Regions",
    43: "Gangwon-do",
    44: "Gyeonggi-do",
    45: "Gyeongsangnam-do",
    46: "Gyeongsangbuk-do",
    47: "Gwangju Metropolitan City",
    48: "Daegu Metropolitan City",
    49: "Daejeon Metropolitan City",
    50: "Busan Metropolitan City",
    51: "Seoul",
    52: "Ulsan Metropolitan City",
    53: "Incheon Metropolitan City",
    54: "Jeollanam-do",
    55: "Jeollabuk do",
    56: "Jeju Special Self -Governing Province",
    57: "Chungcheongnam -do",
    58: "Chung-cheong bukdo",
    59: "Other",
    60: "Period",
    61: "All Periods",
    62: "Last 1 Day",
    63: "Last 1 Week",
    64: "Last 1 Month",
    65: "Last 6 Months",
    66: "Last 1 Year",
    67: "Enter Manually",
    68: "Apply",
    69: "Reset Filters",
    70: "Search",
    71: "Select All",
    72: "Request Data Usage Rights",
    73: "Latitude:",
    74: "Longitude:",
    75: "Data Title",
    76: "Survey Group",
    77: "Download Photo",
    78: "Do you want to download the photo data?",
    79: "OK",
    80: "Cancel",
    81: "Download Excel",
    82: "Do you want to download the Excel data?",
    83: "Download Data",
    84: "Edit Data",
    85: "Delete Data",
    86: "Comments",
    87: "Enter your comments.",
    88: "Submit",
    89: "Data Usage Request Application Form",
    90: "Nickname",
    91: "Applicant (Real Name)",
    92: "Reason for Application",
    93: "Email",
    94: "Contact Number",
    95: "File Attachment",
    96: "※ Please attach a document that proves your affiliation, such as a business card or a proof of employment.",
    97: "Click the Add File button to add files or drag and drop here.",
    98: "Add File +",
    99: "Apply",
    100: "Data Usage Requests Complete",
    101: "Your data usage request has been completed.\nApproval from the data registrar is needed, so please wait a moment.",
    102: "Would you like to download the data?",
    103: "Would you like to delete the data?",
    104: "URL has been copied.\nPlease paste it where you desire.",
    105: "Registration date",
    106: "Registration time",
    107: "Investigation time",
    108: "Investigation location",
    109: "(Latitude, Longitude) :",
    110: "Additional information and descriptions",
    111: "Search tags",
    112: "Form version",
    113: "Form of the survey location",
    114: "Main type of litter",
    115: "Evaluating marine litter clean-up activities",
    116: "Diving condition (depth)",
    117: "Diving condition (water temperature)",
    118: "Diving condition (vision)",
    119: "Substrate of the affected area",
    120: "Aging status of main litter",
    121: "Affected object",
    122: "100m section width",
    123: "Municipality",
    124: "Name of the coastal area",
    125: "Main source of litter",
    126: "Background",
    127: "Cleanup condition",
    128: "Seaweed coverage",
    129: "Evaluating litter quantity",
    130: "Sum of all the sections :",
    131: "Section 1:",
    132: "Section 2:",
    133: "Section 3:",
    134: "Section 4:",
    135: "Section 5:",
    136: "Section 6:",
    137: "Section 7:",
    138: "Section 8:",
    139: "Section 9:",
    140: "Section 10:",
    141: "Additional photo upload",
    142: "Notification",
    143: "You are not logged in.\nWould you like to log in?",
    144: "Submit data",
    145: "Join another group",
    146: "Up to {{%d}} characters can be entered.",
    147: "Investigation area site photo",
    148: "※ You can upload up to 4 photos. Please upload one photo each from the north, south, east, and west directions of the investigation area.",
    149: "Rep.",
    150: "※ You can also enter coordinates by clicking the map.",
    151: "Latitude",
    152: "Longitude",
    153: "Additional Information and explanations (optional)",
    154: "Providing additional information and descriptions about the environmental conditions and situations at the survey location would be helpful for data analysis.",
    155: "Search tags (optional)",
    156: "Please enter tags that can be keywords when searching for data.",
    157: "#tags",
    158: "Submit",
    159: "Sandy beach",
    160: "Pebble beach",
    161: "Tidal shore",
    162: "Rocky shore",
    163: "Other: ",
    164: "Select the main type of litter.",
    165: "Discarded fishing gear (nets, ropes, aquafarming equipment, etc.)",
    166: "Household waste (PET bottles, plastic bags, single-use items, etc.)",
    167: "Industrial waste (waste materials, construction materials, oil barrels, etc.)",
    168: "Regular clean-ups are performed by the local government or other organisations.",
    169: "Unregular clean-ups are performed at the location.",
    170: "Clean-ups are rare due to the absence of people or other reasons.",
    171: "Not judged (temporary shooting)",
    172: "m",
    173: "℃",
    174: "Distance at which a specific object can be identified accurately.",
    175: "Rock",
    176: "sand",
    177: "Mud ",
    178: "Artificial reef (artificial structure)",
    179: "Household waste",
    180: "Industrial waste",
    181: "Record the aging state of the major marine litter.",
    182: "Good: There is no damage, clean condition",
    183: "Middle: Damaged or not clean",
    184: "Poor: Damaged and contaminated",
    185: "Marine animal",
    186: "Sea plants ",
    187: "Underwater structure",
    188: "None",
    189: "Main types of litter (choose one)",
    190: "Styrofoam buoy",
    191: "Large bulky waste (home appliances, tires, etc.)",
    192: "Foreign waste",
    193: "Main sources of litter (choose one)",
    194: "Tourist destination",
    195: "Aquaculture farm",
    196: "Residents",
    197: "River inflow",
    198: "Land use of backgound area (choose one)",
    199: "Farmland",
    200: "Town",
    201: "Beach and tourist attractions",
    202: "Commercial and industrial",
    203: "Good (accessible by vehicles)",
    204: "Middle (accessible by foot)",
    205: "Poor (Not accessible for collection.)",
    206: "75% or more",
    207: "About 50%",
    208: "About 25%",
    209: "Almost none",
    210: "※ Divide 100m of the coastal area into 10m sections and estimate the volume of litter in each section while moving through each section. Only input the numerical value for the estimated volume(Units: liters/10 meters of shoreline).  When you're done entering the numbers, turn around and take one photo each. \n※ Take one photo per section and name the file as follows: Submitter’s Name_Date_Section Number.file extension.",
    211: "Upload additional photos (optional)",
    212: "Project",
    213: "Create a New Project",
    214: "<tag>Group</tag> Introduction",
    215: "Ongoing <tag>Project</tag>",
    216: "Scheduled <tag>Project</tag>",
    217: "Manager Introduction",
    218: "No Manager",
    219: "View Details",
    220: "In Progress",
    221: "Preparing",
    222: "Completed Projects",
    223: "Group",
    224: "Request for Participation",
    225: "Request for Group Participation",
    226: "<tag>{{%s}}</tag> Apply to Join the Group?",
    227: "Request for Group Participation Complete",
    228: "The submission for group participation is complete.\nPlease wait a little for the manager's approval.",
    229: "Manager",
    230: "Opening Date",
    231: "Related Links",
    232: "Survey Target",
    233: "<tag>Total Number of</tag> Data Registration",
    234: "entries",
    235: "<tag>Participants</tag> Ranking",
    236: "Creator",
    237: "Creation Date",
    238: "Affiliated Group",
    239: "Project Period",
    240: "Project Location ",
    241: "Project Introduction",
    242: "<tag>Project</tag> Records",
    243: "End Project",
    244: "Would you like to end the project?",
    245: "Edit Project",
    246: "Delete Project",
    247: "Would you like to delete the project?",
    248: "Project <tag>participants</tag>",
    249: "people",
    250: "<tag>Project</tag> Taker",
    251: "Most Active <tag>Investigator</tag>",
    252: "Create a Project",
    253: "Basic Information",
    254: "Form Format",
    255: "Group Selection",
    256: "Project Name",
    257: "Project Photo",
    258: "Start Date and Time",
    259: "End Date and Time",
    260: "End Date Undecided",
    261: "Select Region",
    262: "Post Project",
    263: "Introduction",
    264: "Edit",
    265: "Save",
    266: "Terms and Policies",
    267: "Inquiry Type",
    268: "Activity Inquiry",
    269: "Partnership Inquiry",
    270: "Technical Inquiry",
    271: "Name",
    272: "Please provide your name.",
    273: "Email",
    274: "Enter your contact email address.",
    275: "Title",
    276: "Please enter a Title.",
    277: "Content",
    278: "Please enter the content.",
    279: "Drag and drop files here.",
    280: "Submit",
    281: "Inquiry Complete",
    282: "Your inquiry has been successfully received.\nWe will respond via email after reviewing it.",
    283: "Login",
    284: "Email Address",
    285: "Password",
    286: "Use at least 8 characters including a combination of upper and lower case letters, numbers, and special characters.",
    287: "Login Failed",
    288: "Incorrect password or \nunregistered email address.",
    289: "Your membership is suspended. \nIf you need further assistance, please inquire via the website's contact form.",
    290: "Auto Login",
    291: "Forgot Password",
    292: "Join Now",
    293: "Enter your email address.",
    294: "Email Authentication",
    295: "If you enter your account email address, we will deliver an authentication code email to you.\nPlease enter the authentication confirmation code in the email verification pop-up to proceed with changing your password.",
    296: "Please enter the authentication confirmation code sent from OCEAN Cloud\nfor verification.",
    297: "Email verification failed",
    298: "Email address is not registered.",
    299: "Authentication confirmation code is incorrent. \nPlease check and try again.",
    300: "Hello,",
    301: "This is your email verification code from Ocean Cloud.\nPlease copy the code below to proceed with verification.",
    302: "This email is automatically generated and cannot be replied to.\nIf you need more assistance, please contact us through Ocean Cloud website.",
    303: "https://cloud.oceanknights.net",
    304: "Change Password",
    305: "Please enter your password. (Minimum of 8 characters)",
    306: "Please enter your password again.",
    307: "Password Changed",
    308: "Your password has been changed.\nPlease proceed with login using the updated password.",
    309: "Passwords do not match.",
    310: "Membership",
    311: "I agree to the Terms of Service, Location-based Service Terms, and Privacy Policy",
    312: "Join with Email Address (age 14 and above only)",
    313: "Already a member?",
    314: "Agree to the Terms and Conditions",
    315: "Agree to the Terms of Service, Location-based Service Terms, and \nPrivacy Policy.",
    316: "Verification Complete",
    317: "Not Available",
    318: "The email address is already registered.",
    319: "Rejoining Not Possible",
    320: "Rejoining is not possible with this email address.",
    321: "Email authentication has not been completed.",
    322: "Please enter a nickname. (Minimum of two characters)",
    323: "Available nickname.",
    324: "Nickname in use. Please use a different one.",
    325: "Nickname should be at least 2 characters.",
    326: "Nickname can only consist of Korean, English, numbers, and spaces.",
    327: "Phone Number",
    328: "Enter numbers only without '-'",
    329: "Address",
    330: "Search for an address",
    331: "Search with the street name, building name, or land lot number in a convenient way.",
    332: "Please enter an address.",
    333: "Address Search Results <tag>{{%s}}</tag> items",
    334: "Address Search Failed",
    335: "Search is not possible with only numbers or special characters.",
    336: "No search results for {{%s}}.",
    337: "Cannot search for {{%s}} with special characters.",
    338: "Enter the remaining address.",
    339: "※ If the address is not entered, the delivery of various \"Knights Goods\" may not be possible.",
    340: "Introduction",
    341: "Please enter an introduction.",
    342: "SNS Address",
    343: "Please enter an SNS address.",
    344: "Join",
    345: "Welcome!",
    346: "Congratulations, <tag>{{%s}}</tag>, on joining as a member of Ocean Cloud.\nJoin a group of your choice and help clean the ocean.",
    347: "Get Started",
    348: "My Page",
    349: "My information",
    350: "Member Management",
    351: "Group Management",
    352: "Approval Management",
    353: "Create Group",
    354: "Log Out",
    355: "Group Members Management",
    356: "Group Members",
    357: "Data Usage Rights",
    358: "Request",
    359: "Group Participation",
    360: "My Info",
    361: "Leave Group",
    362: "Would you like to leave the <tag>{{%s}}</tag> group?",
    363: "You have left the <tag>{{%s}}</tag> group.",
    364: "Membership Withdrawal",
    365: "Account cannot be recovered once membership is withdrawn.\nAre you sure you want to withdraw?",
    366: "You have withdrawn from the <tag>{{%s}}</tag> site.",
    367: "Modify My Information",
    368: "Would you like to change your information?",
    369: "Your information has been successfully updated.",
    370: "Group Name",
    371: "Number of Members",
    372: "Number of Data",
    373: "Request Details",
    374: "Request Date",
    375: "Requested Group",
    376: "Processing Status",
    377: "Approved",
    378: "Pending",
    379: "Rejected",
    380: "Group Participation Approval Completed",
    381: "Your group participation application has been approved.",
    382: "Group Participation Rejected",
    383: "Your group participation application has been rejected.",
    384: "Application Form Entry",
    385: "Already in use group name. Please use a new group name.",
    386: "Group Photo",
    387: "Applicant's Full Name",
    388: "Applicant's Contact Number",
    389: "Affiliation",
    390: "Group Participation\nNeeds Approval",
    391: "Required",
    392: "Not Required",
    393: "Up to 50 characters can be entered.",
    394: "Group name must be at least 2 characters.",
    395: "Group name can only consist of Korean, English, numbers, and spaces.",
    396: "Group Creation Request",
    397: "Apply for <tag>{{%s}}</tag> Group Creation?",
    398: "Group Creation Request Completed",
    399: "The submission for group creation is complete.\nPlease wait a bit for approval from the administrator.",
    400: "Application Form",
    401: "Group Creation Request Rejected",
    402: "The group creation request has been denied.",
    403: "Group Creation Request Form",
    404: "All",
    405: "Cancel Request",
    406: "Cancel Data Usage Rights Request",
    407: "Would you like to cancel the selected data usage rights request?",
    408: "Data Usage Rights Request Canceled",
    409: "The data usage rights request has been canceled.",
    410: "Registered Data",
    411: "Data Usage Rights Request Denied",
    412: "The data usage rights request has been denied.",
    413: "Awaiting Approval",
    414: "Approval History",
    415: "Decline",
    416: "Approve Data Usage Rights Request",
    417: "Would you like to approve the data usage rights request?",
    418: "Reject Data Usage Rights Request",
    419: "The request is denied for the following reasons:",
    420: "Processing Date",
    421: "Rejected",
    422: "The request is rejected for the following reasons:",
    423: "Grade",
    424: "Grand Master",
    425: "Knight in training",
    426: "Knight",
    427: "Senior Knight",
    428: "Group Member Name",
    429: "Group Member Grade",
    430: "Join Date",
    431: "Last Visit Date",
    432: "Number of Posts in the Group",
    433: "Number of Comments in the Group",
    434: "Change Group Member Grade",
    435: "Withdraw Group Membership",
    436: "Send Email",
    437: "Send Email to All",
    438: "Target for Change",
    439: "Would you like to change the grade of the selected group member?",
    440: "Group member grade has been changed.",
    441: "Reason for Withdrawal",
    442: "Illegal Advertising and Spam Activity",
    443: "Undesirable Activity",
    444: "Violation of Operating Principles",
    445: "Are you sure you want to proceed with withdrawal?",
    446: "Group member has been withdrawn.",
    447: "Reason:",
    448: "Recipient",
    449: "Email Sent",
    450: "The email has been successfully sent.",
    451: "Email Sending Failed",
    452: "Failed to send the email.",
    453: "Group Member Information",
    454: "Save Changes",
    455: "Would you like to save the changes?",
    456: "Select Group:",
    457: "Edit Items:",
    458: "Set Group Member Grade",
    459: "Modify Input Form",
    460: "Group Cover Photo",
    461: "Lead Manager",
    462: "Group Member Grade Name",
    463: "Description",
    464: "Group Manager",
    465: "Delete",
    466: "Add",
    467: "Grade Name",
    468: "Delete Grade",
    469: "Would you like to delete the selected grade?",
    470: "Save Complete",
    471: "Changes have been successfully saved.",
    472: "Save Failed",
    473: "Failed to save due to duplicate group member grade names.",
    474: "Short Answer",
    475: "Short Answer(Numeric)",
    476: "Long Answer",
    477: "Multiple Choice Question",
    478: "Checkbox",
    479: "Upload Image",
    480: "Input results",
    481: "Explanation for the Question (Optional)",
    482: "Required",
    483: "Do you want to modify the input form?",
    484: "The input form has been changed.",
    485: "No modifications were made.",
    486: "Approve Group Member Application",
    487: "Would you like to approve the group member application?",
    488: "Reject Group Member Application",
    489: "The application is rejected for the following reasons:",
    490: "The application is rejected for the following reasons:",
    491: "Applicant",
    492: "Membership Grade",
    493: "Regular Member",
    494: "Member Name",
    495: "Member Suspension",
    496: "Suspension Reason",
    497: "Are you sure you want to proceed with suspension?",
    498: "Member has been suspended.",
    499: "Delete Group",
    500: "The group is being deleted for the following reasons:",
    501: "Approve Group Creation",
    502: "Would you like to approve the group creation?",
    503: "Reject Group Creation Request",
    504: "Error",
    505: "An unexpected error has occurred.",
    506: "Not available on mobile. Please access via PC.",
    507: "Please attach the file again in the following format.\nMaximum number of attached files: {N}\nMaximum attachment size: {N} {unit}",
    508: "Please attach the file again in the following format.\nMaximum attachment size: {N} {unit}",
    509: "View on App",
    510: "It's okay. I will view it on mobile web.",
    511: "Photo {{%1$d}}: {{%2$d}}",
    512: "Deleted Project",
    513: "Download Original Photo",
    514: "Cancel Input",
    515: "There are changes.\nDo you want to exit without saving?",
    516: "Types of Litter",
    517: "Quantity",
    518: "Total",
    519: "Investigator:",
    520: "Survey Date:",
    521: "No group description.",
    522: "Please provide a description for the group.",
    523: "You are not currently a member of any group.",
    524: "Try joining the desired group!",
    525: "This is a closed project.",
    526: "Beta test version.",
    527: "The data title contains prohibited content.",
    528: "Either all fields must be filled in or left empty.",
    529: "No selected project.",
    530: "End time must be set later.",
    531: "Photo",
    532: "Enter Quantity of Trash Types",
    533: "Republic of Korea",
    534: "No related project is set.",
    535: "Editing...",
    536: "Submitting...",
    537: "Edit",
    538: "Register with <tag>Safety e-Report</tag> operated by the Ministry of Public Safety and Security for quick trash collection.",
    539: "You do not have access with the current permissions.",
    540: "Submit",
    541: "Changes have been saved.",
    542: "Select Lead Manager",
    543: "Approval Required for Group Participation",
    544: "Option#",
    545: "Add Option",
    546: "Or",
    547: "Add Others",
    548: "Partial Input",
    549: "Section#:",
    550: "Unit",
    551: "Option 1",
    552: "Number of Photos to Input",
    553: "Please attach the file again in the following format.",
    554: "Maximum number of attached files: {{%d}}",
    555: "Maximum attachment size: {{%d}}MB",
    556: "Attachment Failed",
    557: "Only image files can be uploaded.",
    558: "Now drop an image.",
    559: "Drop or Click an Image",
    560: "Cannot search for specific characters like \"{{%s}}\".",
    561: "No search results for <tag>{{%s}}</tag>.",
    562: "{{AA}}\nLand Number: {{BB}} | {{DD}}",
    563: "Address search results # items",
    564: "Title Area",
    565: "Member Information",
    566: "Close",
    567: "Group validation is required.",
    568: "Group name is available.",
    569: "Would you like to withdraw from the {{%s}} group?",
    570: "Voluntary Withdrawal",
    571: "You have withdrawn from the {{%s}} group.",
    572: "Group Manager ({{%s}})",
    573: "Regular Member ({{%s}})",
    574: "Please enter a password (at least 8 characters)",
    575: "Re-enter your password",
    576: "Close Modal",
    577: "View on App",
    578: "Notice Email",
    579: "Data Usage Request Approved",
    580: "Data Usage Request has been approved.",
    581: "This email message is automatically generated and cannot be replied to.\nIf you need further assistance, please contact us through the <tag>Ocean Cloud</tag> website.",
    582: "Group Creation Approved",
    583: "The group creation request has been approved.",
    584: "Group Participation Request Rejected",
    585: "Data Usage Request",
    586: "Data usage request have been requested.",
    587: "Group creation has been requested.",
    588: "Group participation has been requested.",
    589: "Verify Email",
    590: "{{%s}} completed.",
    591: "AM",
    592: "PM",
    593: "Error occurred during model loading",
    594: "Urban Knights",
    595: "URBAN",
    596: "Knights",
    597: "Custom",
    598: "Remove Label",
    599: "Do you want to remove the label?",
    600: "Drag the trash to proceed with labeling.",
    601: "Do not watch it again.",
    602: "Please select the type of trash",
    603: "Retry",
    604: "Please keep the angle parallel to the ground and take the shot.",
    605: "You can log in with your Ocean Cloud account. Please use Ocean Cloud for signing up or finding your password.",
    606: "Ocean Cloud",
    607: "Login not possible",
    608: "This is a dedicated app for Urban Knights. Please use it after signing up for Uban Knights.",
    609: "Network not detected",
    610: "No network connection available.\nPlease connect to the network and try again.",
    611: "Permission denied",
    612: "Camera",
    613: "Read image media",
    614: "Read storage",
    615: "Write storage",
    616: "Media location confirmation",
    617: "Detailed location",
    618: "Internet",
    619: "Network status check",
    620: "User guide",
    621: "App exit",
    622: "Required permission notice",
    623: "To use {{%1$s}}, {{%2$s}} permission is required.",
    624: "Permission settings",
    625: "App resource download",
    626: "To use {{%1$s}}, app resource download is required.\n\nWould you like to download the app resource?\n\nResource size: {{%2$s}}",
    627: "Download",
    628: "Downloading",
    629: "※ Additional trash items to be added in the future",
    630: "Uploading files.\nPlease retry after the upload is completed.",
    631: "Year-Month-Day",
    632: "-- --:--",
    633: "There is temporary data saved.\nDo you want to continue from where you left off?",
    634: "Delete data",
    635: "Save as draft",
    636: "Data has been temporarily saved.",
    637: "You can continue writing by submitting the data.",
    638: "The data will be permanently deleted.",
    639: "Are you sure you want to delete it?",
    640: "Press 'Back' button again to exit the app.",
    641: "Failed to capture photo",
    642: "Photo capture failed due to camera shake.\nPlease try again.",
    643: "https://www.safetyreport.go.kr/eng/#safereport/safereport2",
    644: "Collaborator",
    645: "AI Model Class",
    646: "Special characters are not allowed.",
    647: "Project creation failed",
    648: "No permission to create a project.",
    649: "Please contact the group manager.",
    650: "There is temporary data saved.",
    651: "Do you want to continue from where you left off?",
    652: "Title",
    653: "View on the web",
    654: "Kakao",
    655: "Link timeout has expired.",
    656: "Data name",
    657: "Application for utilizing data for public interest organizations",
    658: "If you take a photo directly from the app, the photos will not be saved on your smartphone. If you wish to save the photos on your smartphone, take photos with your smartphone camera first, then import the photos to enter the data.",
    659: "Remove",
    660: "Sign up with Facebook account",
    661: "Sign up with Kakao account",
    662: "Frequently Asked Questions",
    663: "Question",
    664: "Answer",
    665: "Group name",
    666: "Approval for utilizing data for public interest organizations",
    667: "Do you want to approve utilizing data for public interest organizations?",
    668: "Reject application for utilizing data for public interest organizations",
    669: "Delete authorization for utilizing data for public interest organizations",
    670: "Delete authorization for utilizing data for public interest organizations with the following reasons.",
    671: "Utilizing data for public interest organizations",
    672: "Modification items",
    673: "Failed to obtain notification information.",
    674: "Request pending",
    675: "Approval for group creation application is completed",
    676: "Application for utilizing data for public interest organizations",
    677: "Do you want to apply for utilizing the data for public interest organizations?",
    678: "Successfully applied for utilizing data for public interest organizations",
    679: "Submission for utilizing data for public interest organizations has been completed.\nPlease wait a little for approval from the group manager.",
    680: "Application for utilizing data for public interest organizations rejected",
    681: "Application for utilizing data for public interest organizations has been rejected.",
    682: "For non-profit purposes such as education and research, the organization can download and utilize all materials from the applied group.",
    683: "No group to apply to.",
    684: "Apply to a group",
    685: "Position",
    686: "Affiliation proof",
    687: "Project creation permission",
    688: "User level",
    689: "No downloadable data available.",
    690: "No matching data found.",
    691: "Year {{%1$d}} Month {{%2$d}}",
    692: "Total collected data count",
    693: "Data count by region",
    694: "Data count by group",
    695: "The group has been deleted.",
    696: "Utilization application for public interest organizations has been approved.",
    697: "You have been withdrawn from the membership.",
    698: "Email verification confirmed",
    699: "Input the number of survey buttons",
    700: "Graph type:",
    701: "Select graph",
    702: "Pie chart",
    703: "Donut chart",
    704: "Horizontal bar chart",
    705: "Vertical bar chart",
    706: "Data title",
    707: "Start time",
    708: "End time",
    709: "※ Please add 'Location tag' in the settings before taking photos with your smartphone to know the shooting location.",
    710: "Manual input",
    711: "Search tags (optional)",
    712: "Beverage Bottle (Plastic)",
    713: "Plastic Bag (Disposable Shopping Bag)",
    714: "Food Container (Styrofoam, Lunch Box, etc.)",
    715: "Food Packaging (Snack, Instant Noodle Wrappers)",
    716: "Cigarette Butt",
    717: "Firecracker (Fireworks Item)",
    718: "Rope",
    719: "Tire",
    720: "Fishing Line (Including Lead Weight, Fishing Hook Attached)",
    721: "Please enter a nickname with at least 2 characters.",
    722: "Section",
    723: "Select the main type of marine debris",
    724: "Record the aging status of main debris",
    725: "Did you add 'Geotagging' in your camera settings before shooting with your smartphone?",
    726: "Before shooting with your smartphone, add a “Geotag” in the settings so you know where you're shooting from.",
    727: "Yes",
    728: "Divide 100m of the coastline into 10m sections and estimate the volume of litter in each section while moving through each section. Only input the numerical value for the estimated volume (Units: liters/10m of shoreline).  When you're done entering the numbers, turn around and take one photo each section.",
    729: "Data usage agreement",
    730: "\nThe copyright (exclusive rights) of the data belongs to the submitter. The submitted data will be used for public purposes only.\n\nAgrees to the use of submitted photos, videos, etc. by BaDaGiSaDan for public purposes.\n - Usage media: Cafes, websites, Facebook, Instagram, YouTube, etc., SNS, newspapers, magazines, broadcast media, etc.\n - Purpose of use:\n 1) Used to identify the quantity and distribution of marine litter.\n 2) Identifying areas where urgent marine litter management is needed based on quantity and distribution.\n 3) Producing and distributing promotional material, educational material, and policy data using the collected data.\n 4) Evaluating whether trash is actually decreasing over a long period using accumulated data.\n 5) Sharing activity results, conducting public campaigns, artwork exhibitions, etc.\n - User: BaDaGiSaDan, cooperation agencies approved by BaDaGiSaDan\n\nUnless otherwise specified by the author, permission is granted to build a database of works, distribute, transmit, and reprocess them through telecommunication networks including the Internet. The use of works other than the listed usage media, purposes, and users can proceed after prior consultation with the original author.\n",
    731: "Section 1",
    732: "(Unit: liters/10 meters of shoreline)",
    733: "Section 2",
    734: "Section 3",
    735: "Section 4",
    736: "Section 5",
    737: "Diving condition (depth m)",
    738: "Diving condition (water temperature ℃)",
    739: "Diving condition (visibility m)",
    740: "Large bulky waste (e.g., appliances, tires, etc.)",
    741: "Section 6",
    742: "Section 7",
    743: "Section 8",
    744: "Section 9",
    745: "Section 10",
    746: "Diving condition",
    747: "Water depth:",
    748: "Water temperature: ",
    749: "Visibility (the distance at which a specific object can be accurately identified):",
    750: "Start point (Latitude, Longitude)",
    751: "Latitude and longitude:",
    752: "Section width: ",
    753: "Did you add 'Geotagging' in your camera settings before shooting with your smartphone?",
    754: "Survey location:",
    755: "Drain",
    756: "Roadside",
    757: "Vacant lot",
    758: "River side",
    759: "Survey area (㎡)",
    760: "Results",
    761: "Food packaging (snacks, ramen package)",
    762: "Beaches",
    763: "Results",
    764: "Firecracker shells (plastic)",
    765: "Firecracker Wire (metal)",
    766: "Paper packaging, etc.",
    767: "Damage survey results",
    768: "Have you been affected by fireworks or unattended firework litter?",
    769: "How to stop firecracker litter?",
    770: "What steps can be taken to eliminate firecracker litter?",
    771: "Crack down on the sale of firecrackers (it is not illegal to sell firecrackers)",
    772: "Crack down on the purchase and use of firecrackers (it is illegal to buy and use)",
    773: "Posting informational signs at facilities near the beach",
    774: "Installing bins to collect firecracker trash",
    775: "Improve laws to prevent the sale of firecrackers",
    776: "Widespread public promotional campaigns",
    777: "Photo contest for public promotion",
    778: "We are holding a photo contest to raise awareness about preventing firework litter among the public. Please submit your best photos! (First submission deadline: November 31, 2023)",
    779: "Take a photo of firecracker litter you find on the beach and record the type and number of pieces in the photo. After recording, collect the firecracker trash.",
    780: "Firecracker shell (in the form of a dark gray plastic tube about the size of a cigarette butt)",
    781: "Firecracker wire (approximately 20 centimeters of wire)",
    782: "Paper packaging, etc. (shaped like rods or cubes)",
    783: "How to eliminate firecracker Litter",
    784: "What should be done first to eliminate firecracker litter (please select only 3 options)?",
    785: "Please write the name of the area to be surveyed (OOO beach, OO seawall, etc.).",
    786: "Seawall",
    787: "Shoreline (not a swimming beach) / accommodation",
    788: "Firecracker litter survey results",
    789: "Take a photo of firecracker litter you find on the beach and record the type and number of pieces in the photo. After recording, collect the firecracker trash. (You can upload up to 20 photos.)",
    790: "What should be done first to eliminate firecracker litter (please select only 3 options)?",
    791: "Photo contest for public promotion",
    792: "We are holding a photo contest to raise awareness about preventing firework litter among the public. Please submit your best photos! You can upload up to 10 photos. Please refer to OSEAN's website for the contest awards. After awarding, the works will be used for promotion with copyright indication (Submission deadline for the first round: October 31, 2023). For more details, visit www.osean.net.",
    793: "Main street",
    794: "Alley",
    795: "Around shopping centers",
    796: "Plastic beverage bottles_bottled bater",
    797: "Plastic beverage bottles_other than bottled bater",
    798: "Styrofoam bontainers (cup noodles, food packaging, fruit packaging, etc.)",
    799: "Plastic food packaging (snacks, ramen packaging)",
    800: "Plastic bags (Disposable, Takeout Packaging, etc.)",
    801: "Plastic takeout cups",
    802: "Paper takeout cups",
    803: "Paper packaging (shopping bags, delivery boxes, etc.)",
    804: "Wet tissues",
    805: "How to eliminate firecracker litter - subjective answer",
    806: "What should be done first to eliminate firecracker litter? Please feel free to share your opinions.",
    807: "Public promotion photo contest (deadline: November 30)",
    808: "We are holding a photo contest to raise awareness about preventing firework litter among the public. Please submit your best photos! You can upload up to 10 photos. Please refer to the Ocean website for the contest awards. After awarding, the works will be used for promotion with copyright indication (Submission deadline for the first round: November 31, 2023). For more details, visit www.osean.net.",
    809: "What should be done first to eliminate firecracker litter (please select only 3 options)?",
    810: "Public promotion photo contest",
    811: "We're looking for photos that can be used in promotional materials to educate the public about eliminating firecracker debris.",
    812: "Take a photo of the firecracker litter you find on the beach and record the type and number of items in the photo. After recording, collect the firecracker debris (only record what you see in the photo, you can upload up to 20 photos)",
    813: "What should be done first to eliminate firecracker litter (please select only 3 options)?",
    814: "We are holding a photo contest to raise awareness about preventing firework litter among the public. Please submit your best photos! You can upload up to 10 photos. For more details, visit www.osean.net.",
    815: "Streams / Riverside",
    816: "Survey area (㎡) (optional)",
    817: "Plastic beverage bottles and caps (crinking water)",
    818: "Plastic beverage bottles and caps (various beverages)",
    819: "Styrofoam packaging (ramen noodles, food, fruit, etc.)",
    820: "Plastic packaging containers (food, snacks, etc.)",
    821: "Plastic takeout cups and straws",
    822: "Paper takeout cups and straws",
    823: "What trash did you find the most?",
    824: "Please freely write down any ideas you would like to propose to local governments and the central government to fundamentally reduce waste, as well as wise methods that individuals can practice.",
    825: "※ By pressing the + icon, you can take a photo or select one from your gallery to upload. After uploading the photo, press the input button below to record the types and quantities of firework debris in the photo. (Record only what is in the photo. You can upload up to 20 photos.)",
    826: "What trash do you see when you look at it before cleaning?",
    827: "Where does this trash usually come from?",
    828: "What kind of facilities are around the coast?",
    829: "Commercial and industrial areas",
    830: "Is it easy to transport the trash collected here after the cleanup?",
    831: "Investigate the 10 items in the TEN2ONE Campaign",
    832: "Please investigate the top 10 most abundant and harmful items in our country's sea. By focusing on cleaning up these 10 items, we can make our sea cleaner and safer more efficiently.",
    833: "Fishing rope",
    834: "Plastic bottles (bottles and caps, for beverages)",
    835: "Plastic for food packaging (coffee bags, ramen package, snack bags, etc.)",
    836: "Plastic bags",
    837: "Recreational fishing debris (fishing line, hooks, sinker, fake bait, plastic packaging for fishing tackle, etc.)",
    838: "Firecracker debris (sparkler shells or wire, roman candle-type paper rods, etc.)",
    839: "Plastic string (aquaculture or packaging)",
    840: "Eel trap",
    841: "The total number of trash collected, including all 10 categories.",
    842: "We will clean up all trash except for the 10 designated items of the 'TEN2ONE Campaign' without recording them. After cleaning, please record the amount collected (estimate the number of 20-liter trash bags).",
    843: "Number of bags",
    844: "#TEN2ONE #styrofoam #buoy #rope #plastic #bottle #packaging #bag #fishing #cigarette #string #eeltrap",
    845: "Please freely write ideas that you would like to propose to municipalities and the government to fundamentally reduce waste, actions that businesses can take, and practices that individuals can adopt.",
    846: "Message to the Sea",
    847: "Investigate the 10 items of the 'TEN2ONE' ",
    848: "Investigate the 10 items of the 'TEN2ONE'",
    849: "The total amount of collected trash including the 10 items. Enable recording of the number of bags and the weight. Based on a 20-liter bag standard (number of bags, weight in kg).",
    850: "We will clean up all trash except for the 10 designated items of the 'TEN2ONE Campaign' without recording them. After cleaning, please record the amount collected (estimate the number of 20-liter trash bags).",
    851: "Additional comments",
    852: "Please freely write ideas that you would like to propose to municipalities and the government to fundamentally reduce waste, actions that businesses can take, and practices that individuals can adopt.",
    853: "Please post a picture of your cleaned up beach.",
    854: "Investigate the 10 designated items of the 'TEN2ONE' ",
    855: "Photos of unusual trash",
    856: "If you encounter any unusual or noteworthy debris items outside of the 10 designated items, please upload a photo.",
    857: "Number of bags and weight of trash collected",
    858: "Thanks to your dedication, the sea has become cleaner. You can upload a proof photo.",
    859: "Feel free to upload photos of the beach, your activities, and more.",
    860: "Please investigate the top 10 most abundant and harmful items in our country's sea. By focusing on cleaning up these 10 items, we can make our sea cleaner and safer more efficiently.",
    861: "Other",
    862: "Number of bags ",
    863: "We will clean up all trash except for the 10 designated items of the 'TEN2ONE Campaign' without recording them. After cleaning, please record the amount collected (estimate the number of 20-liter trash bags).",
    864: "Photos of unusual or interesting trash (up to 3)",
    865: "Thanks to your dedication, the sea has become cleaner. Please take a photo. (up to 5)",
    866: "Photo {{%d}} [File Name]",
    867: "Photo {{%d}} [Total]",
    868: "[Section Total]",
    869: "[Segmental File]",
    870: "(Unit: {{%s}})",
    871: "Total Quantity: {{%d}}",
    872: "Sejong City",
    873: "Image attachment failed",
    874: "[{{%s}}] The resolution of the image is too high. Please convert it to a resolution below 8K before uploading.",
    875: "Website management",
    876: "Object Detection AI Model",
    877: "Not set",
    878: "Register AI model",
    879: "AI Model List",
    880: "Model Version",
    881: "Class list",
    882: "Model file",
    883: "Model type",
    884: "Model description",
    885: "※ Enter the class list separated by `,`. ex) tree, eraser",
    886: "Please enter a model description (optional)",
    887: "Register",
    888: "Model Type (e.g., yolov5)",
    889: "Function Check",
    890: "Sign in with Apple",
    891: "Sign in with Google",
    892: "You are not registered with an [소셜명] account.",
    893: "You cannot log in with an [소셜명] account.",
    894: "Sign up with Apple",
    895: "Sign up with Google",
    896: "Real Name",
    897: "Please enter your actual name.",
    898: "Retake",
    899: "Enter quantity",
    904: "Enter Account Info",
    905: "Agree",
    906: "To continue using Ocean Cloud, please agree to the newly updated terms and re-enter your account information.",
    907: "Change: Collection of Real Name",
    908: "Please enter your real name.",
    911: "Realname can only consist of Korean, English and spaces.",
    912: "Realname should be at least 2 characters.",
};
const ko = {
    1: "OCEAN Cloud",
    2: "자료보기",
    3: "프로젝트",
    4: "더 보기",
    5: "사이트 소개",
    6: "약관 및 정책",
    7: "문의하기",
    8: "자료 제출하기",
    9: "로그인",
    10: "<tag>마이</tag> 페이지",
    11: "이메일무단수집거부",
    12: "바다기사단 커뮤니티",
    13: "안전신문고",
    14: "오션 클라우드 소개\n서울특별시 금천구 가산디지털1로 168, A동 1102호 (가산동, 우림라이온스밸리)\n대표번호 : 02-3442-7236 / 팩스 : 02-3442-7124 / 문의메일 : oceancloud@devguru.co.kr\n주식회사 데브구루 / 대표이사 : 송지호 / 사업자등록번호 : 114-86-37116",
    15: "필수 항목입니다.",
    16: "올바른 이메일 주소를 입력해 주세요.",
    17: "원하는 해양 자료를 검색해보세요.",
    18: "바다기사단 자료 수집 현황",
    19: "수집 자료 건수",
    20: "완료된 <tag>프로젝트</tag>",
    21: "진행중인 프로젝트",
    22: "조사원",
    23: "조사 지역",
    24: "최근 등록 자료",
    25: "해양 상태를 더 정확히 파악하기 위해 많은 표본이 필요합니다.\n시민 과학자들이 직접 등록한 자료를 통해 우리의 해양 상태를 확인해보세요.",
    26: "조사자",
    27: "조사날짜",
    28: "GROUP",
    29: "NEW",
    30: "더 많은 자료보기 +",
    31: "PROJECT",
    32: "다양한 프로젝트를 통해 테마별 해양 상태를 확인할 수 있습니다.",
    33: "Data",
    34: "자료 검색",
    35: "검색 조건",
    36: "해당되는 자료가 없습니다.",
    37: "내 자료",
    38: "그룹",
    39: "그룹 전체",
    40: "프로젝트 전체",
    41: "지역",
    42: "지역 전체",
    43: "강원도",
    44: "경기도",
    45: "경상남도",
    46: "경상북도",
    47: "광주광역시",
    48: "대구광역시",
    49: "대전광역시",
    50: "부산광역시",
    51: "서울특별시",
    52: "울산광역시",
    53: "인천광역시",
    54: "전라남도",
    55: "전라북도",
    56: "제주특별자치도",
    57: "충청남도",
    58: "충청북도",
    59: "기타",
    60: "기간",
    61: "기간 전체",
    62: "최근 1일",
    63: "최근 1주",
    64: "최근 1개월",
    65: "최근 6개월",
    66: "최근 1년",
    67: "직접 입력",
    68: "적용",
    69: "필터 초기화",
    70: "검색",
    71: "전체 선택",
    72: "자료 사용권 요청",
    73: "위도 :",
    74: "경도 :",
    75: "자료제목",
    76: "조사그룹",
    77: "사진 다운로드",
    78: "사진 자료를 다운로드 하시겠습니까?",
    79: "확인",
    80: "취소",
    81: "엑셀 다운로드",
    82: "엑셀 자료를 다운로드 하시겠습니까?",
    83: "자료 다운로드",
    84: "자료 수정",
    85: "자료 삭제",
    86: "댓글",
    87: "댓글을 입력하세요.",
    88: "등록",
    89: "자료 사용권 신청서",
    90: "닉네임",
    91: "신청자 (실명)",
    92: "신청 사유",
    93: "이메일",
    94: "연락처",
    95: "파일 첨부",
    96: "※ 소속을 증명할 수 있는 문서를 첨부해주세요. ex) 명함 or 재직증명서 등",
    97: "파일 추가 버튼을 클릭하여 파일을 추가하거나 여기에 드래그한 후 놓으십시오.",
    98: "파일 추가 +",
    99: "신청하기",
    100: "자료 사용권 신청 완료",
    101: "자료 사용권 신청이 완료되었습니다.\n자료 등록자의 승인이 필요하니 조금만 기다려주세요.",
    102: "자료를 다운로드 하시겠습니까?",
    103: "자료를 삭제하시겠습니까?",
    104: "URL이 복사되었습니다.\n원하는 곳에 붙여넣기 해주세요.",
    105: "등록날짜",
    106: "등록시간",
    107: "조사시간",
    108: "조사위치",
    109: "(위도, 경도) :",
    110: "추가 정보 및 설명",
    111: "검색태그",
    112: "폼 버전",
    113: "촬영구역의 형태",
    114: "주요쓰레기 종류",
    115: "해양쓰레기 수거 활동 판단",
    116: "다이빙 상태 (수심)",
    117: "다이빙 상태 (수온)",
    118: "다이빙 상태 (시야)",
    119: "피해지형",
    120: "주요쓰레기 노후상태",
    121: "피해대상",
    122: "100m 구간 폭",
    123: "시군(지역주소)",
    124: "고유지역(해안)명",
    125: "주요쓰레기 발생원",
    126: "배후지",
    127: "청소 용이성",
    128: "해조류 피복도",
    129: "쓰레기 양 평가",
    130: "구간 합 :",
    131: "구간1 :",
    132: "구간2 :",
    133: "구간3 :",
    134: "구간4 :",
    135: "구간5 :",
    136: "구간6 :",
    137: "구간7 :",
    138: "구간8 :",
    139: "구간9 :",
    140: "구간10 :",
    141: "추가 사진 업로드",
    142: "알림",
    143: "로그인이 되어 있지 않습니다.\n로그인 하시겠습니까?",
    144: "자료 제출",
    145: "다른 그룹 가입하기",
    146: "{{%d}}자까지 입력 가능합니다.",
    147: "조사지 현장사진",
    148: "※ 최대 4장까지 업로드 가능합니다. 조사지 동서남북 방향 사진을 한 장씩 올려주세요.",
    149: "대표",
    150: "※ 지도를 클릭해 좌표를 입력할 수도 있습니다.",
    151: "위도",
    152: "경도",
    153: "추가 정보 및 설명 (선택)",
    154: "조사위치에 대한 환경, 상황에 대한 추가 정보 및 설명을 해주시면 자료 통계 시 유용합니다.",
    155: "검색태그 (선택)",
    156: "자료 검색 시 키워드가 될 수 있는 태그를 입력해주세요.",
    157: "#태그",
    158: "제출하기",
    159: "모래 해안",
    160: "자갈 해안",
    161: "갯벌 해안",
    162: "암반 해안",
    163: "기타 :",
    164: "주요쓰레기 종류를 선택합니다.",
    165: "폐어구(그물, 밧줄, 양식 자재 등)",
    166: "생활쓰레기(플라스틱 PET병, 봉지, 일회용품 등)",
    167: "산업쓰레기(폐자재, 건설자재, 기름통 등)",
    168: "지자체 및 기타 단체들에 의해 주기적으로 수거가 이루어지는 곳",
    169: "주기적이진 않지만 수거가 이루어지는 곳",
    170: "인적이 드물거나 기타 사유로 수거가 되지 않는 곳 ",
    171: "판단되지 않음(일시적 촬영)",
    172: "m",
    173: "℃",
    174: "특정 물체를 정확히 확인할 수 있는 거리",
    175: "암반",
    176: "모래",
    177: "진흙(뻘)",
    178: "인공어초(인공 구조물)",
    179: "생활쓰레기",
    180: "산업쓰레기",
    181: "주요쓰레기의 노후 상태를 기록합니다.",
    182: "상 : 손상이 없고, 깨끗한 상태",
    183: "중 : 손상이 있거나, 깨끗하지 않은 상태",
    184: "하 : 손상이 있고, 깨끗하지 않은 상태",
    185: "바다 동물",
    186: "바다 식물",
    187: "수중 구조물",
    188: "없음",
    189: "주요쓰레기 종류 (택1)",
    190: "스티로폼 부표",
    191: "대형 투기 쓰레기(가전제품, 타이어 등)",
    192: "외국쓰레기",
    193: "주요쓰레기 발생원 (택1)",
    194: "관광지",
    195: "양식장",
    196: "마을주민",
    197: "하천유입",
    198: "배후지 (택1)",
    199: "농경지",
    200: "마을",
    201: "해수욕장 및 관광지",
    202: "상업 및 공업",
    203: "상(차량 접근 가능)",
    204: "중(도보 접근 가능)",
    205: "하(수거 불가)",
    206: "75% 이상",
    207: "50% 정도",
    208: "25% 정도",
    209: "거의 없음",
    210: "※해안 100m 를 10m 씩 나눠, 한 구간씩 이동하면서 10m 구간에 있는 쓰레기의 부피를 어림하여 숫자만 입력합니다.\n  숫자 입력이 끝나면 되돌아 오면서 사진을 1장씩 찍습니다. (단위: 리터/10m 해안길이)\n※구간당 1장씩, 파일명: 제출자이름_날짜_구간번호.확장자",
    211: "추가 사진 업로드 (선택)",
    212: "Project",
    213: "새 프로젝트 만들기",
    214: "<tag>그룹</tag> 소개",
    215: "현재 진행중인 <tag>프로젝트</tag>",
    216: "예정된 <tag>프로젝트</tag>",
    217: "매니저 소개",
    218: "매니저 없음",
    219: "자세히 보기",
    220: "진행중",
    221: "준비중",
    222: "완료프로젝트",
    223: "Group",
    224: "참여 신청",
    225: "그룹 참여 신청",
    226: "<tag>{{%s}}</tag> 그룹 참여를 신청하시겠습니까?",
    227: "그룹 참여 신청 완료",
    228: "그룹 참여 신청을 위한 정보제출이 완료되었습니다.\n그룹 매니저의 승인이 필요하니 조금만 기다려 주세요.",
    229: "운영자",
    230: "개설일",
    231: "관련 링크",
    232: "조사 대상",
    233: "<tag>전체 자료</tag> 등록 건수",
    234: "건",
    235: "<tag>참여자</tag> 랭킹",
    236: "개설자",
    237: "개설 날짜",
    238: "소속 그룹",
    239: "프로젝트 기간",
    240: "프로젝트 지역",
    241: "프로젝트 소개",
    242: "<tag>프로젝트</tag> 기록",
    243: "프로젝트 종료",
    244: "프로젝트를 종료하시겠습니까?",
    245: "프로젝트 수정",
    246: "프로젝트 삭제",
    247: "프로젝트를 삭제하시겠습니까?",
    248: "프로젝트 <tag>참여자</tag>",
    249: "명",
    250: "<tag>프로젝트</tag> 토크",
    251: "최다 <tag>조사자</tag>",
    252: "프로젝트 만들기",
    253: "기본 정보",
    254: "폼 형식",
    255: "그룹 선택",
    256: "프로젝트 이름",
    257: "프로젝트 사진",
    258: "시작일시",
    259: "종료일시",
    260: "종료일시 미정",
    261: "지역선택",
    262: "프로젝트 게시",
    263: "Introduction",
    264: "편집",
    265: "저장",
    266: "Terms and Policies",
    267: "문의 유형",
    268: "활동 문의",
    269: "제휴 문의",
    270: "기술 문의",
    271: "이름",
    272: "이름을 알려주세요.",
    273: "메일",
    274: "연락받으실 메일 주소를 입력해 주세요.",
    275: "제목",
    276: "제목을 입력해 주세요.",
    277: "내용",
    278: "내용을 입력해 주세요.",
    279: "파일을 여기에 드래그한 후 놓으십시오.",
    280: "보내기",
    281: "문의 접수 완료",
    282: "문의하신 내용이 정상적으로 접수되었습니다.\n확인 후 메일로 회신드리겠습니다.",
    283: "Login",
    284: "이메일 주소",
    285: "비밀번호",
    286: "8자 이상 영문 대소문자, 숫자, 특수문자 중 2개 이상을 조합하여 사용하세요.",
    287: "로그인 실패",
    288: "비밀번호가 맞지 않거나 \n등록되지 않은 이메일 주소입니다.",
    289: "회원 정지 상태입니다. \n추가 문의가 필요한 경우 홈페이지의 문의하기를 \n통해 문의해주세요.",
    290: "자동 로그인",
    291: "비밀번호 찾기",
    292: "회원가입",
    293: "이메일 주소를 입력하세요.",
    294: "메일 인증",
    295: "회원님의 계정 이메일 주소를 입력하시면 인증 코드 메일을 전달해 드립니다.\n메일 인증 팝업에 인증 확인 코드를 입력하여 비밀번호 변경을 진행해주세요.",
    296: "메일을 인증하려면 OCEAN Cloud로부터 전송된 \n인증 확인 코드를 입력해주세요.",
    297: "메일 인증 실패",
    298: "등록되지 않은 이메일 주소입니다.",
    299: "인증코드가 맞지 않습니다.\n인증코드 확인 후 다시 진행해주세요.",
    300: "안녕하세요.",
    301: "오션 클라우드의 이메일 인증 확인 코드입니다.\n아래 코드를 복사하여 인증을 진행하시기 바랍니다.",
    302: "이 메일 메시지는 자동으로 생성되었으므로 회신이 불가합니다.\n추가적인 도움이 필요하시면 오션 클라우드 홈페이지를 통해 문의해주세요.",
    303: "https://cloud.oceanknights.net",
    304: "비밀번호 변경",
    305: "비밀번호를 입력하세요. (8자리 이상)",
    306: "비밀번호를 다시 입력하세요.",
    307: "비밀번호 변경 완료",
    308: "비밀번호 변경이 완료되었습니다.\n변경된 비밀번호로 로그인을 진행하시기 바랍니다.",
    309: "비밀번호가 일치하지 않습니다.",
    310: "Membership",
    311: "이용 약관, 위치기반 서비스 이용 약관, 개인정보 수집/이용 동의",
    312: "이메일 주소로 가입 (만 14세 이상)",
    313: "이미 회원이신가요?",
    314: "약관 동의",
    315: "이용 약관, 위치기반 서비스 이용 약관, \n개인정보 수집/이용 동의가 필요합니다.",
    316: "인증 완료",
    317: "사용 불가",
    318: "이미 가입된 이메일 주소입니다.",
    319: "재가입 불가",
    320: "해당 메일로는 재가입이 불가능합니다.",
    321: "이메일 인증이 완료되지 않았습니다.",
    322: "닉네임을 입력하세요. (두 글자 이상)",
    323: "사용할 수 있는 닉네임입니다.",
    324: "사용 중인 닉네임입니다. 새로운 닉네임을 사용해 주세요.",
    325: "닉네임은 2자 이상 입력해 주세요.",
    326: "닉네임은 한글, 영문, 숫자, 띄어쓰기만 가능합니다.",
    327: "휴대폰",
    328: "- 없이 숫자만 입력",
    329: "주소",
    330: "주소 검색",
    331: "도로명, 건물명 또는 지번 중 편한 방법으로 검색하세요.",
    332: "주소를 입력해 주세요.",
    333: "주소 검색결과 <tag>{{%s}}</tag> 건",
    334: "주소 검색 실패",
    335: "숫자, 특수문자만으로는 검색이 불가능 합니다.",
    336: "{{%s}} 에 대한 검색 결과가 없습니다.",
    337: "{{%s}} 와(과) 같은 특정문자로 검색할 수 없습니다.",
    338: "나머지 주소를 입력하세요.",
    339: "※ 미입력 시 다양한 기사단 용품 배송이 어려울 수 있습니다.",
    340: "자기소개",
    341: "자기소개를 입력해 주세요.",
    342: "SNS 주소",
    343: "SNS 주소를 입력해 주세요.",
    344: "가입하기",
    345: "환영합니다!",
    346: "<tag>{{%s}}</tag> 님, OCEAN Cloud의 회원 가입을 축하합니다.\n원하는 그룹에 가입해 깨끗한 바다 만들기에 동참해 주세요.",
    347: "시작하기",
    348: "My page",
    349: "내 정보 자세히",
    350: "회원 관리",
    351: "그룹 관리",
    352: "승인 관리",
    353: "그룹 생성",
    354: "로그 아웃",
    355: "그룹원 관리",
    356: "그룹원",
    357: "자료 사용권",
    358: "요청하기",
    359: "그룹 참여",
    360: "내 정보",
    361: "그룹 탈퇴",
    362: "<tag>{{%s}}</tag> 그룹에서 탈퇴하시겠습니까?",
    363: "<tag>{{%s}}</tag> 그룹에서 탈퇴하였습니다.",
    364: "회원 탈퇴",
    365: "회원 탈퇴 시 계정을 복구할 수 없습니다.\n정말 탈퇴하시겠습니까?",
    366: "<tag>{{%s}}</tag> 사이트에서 탈퇴하였습니다.",
    367: "내 정보 변경",
    368: "내 정보를 변경하시겠습니까?",
    369: "내 정보가 정상적으로 변경되었습니다.",
    370: "그룹 이름",
    371: "멤버 수",
    372: "자료 수",
    373: "요청내역",
    374: "신청 날짜",
    375: "신청 그룹",
    376: "처리 상태",
    377: "승인",
    378: "대기중",
    379: "거절",
    380: "그룹 참여 승인 완료",
    381: "그룹 참여 신청이 승인되었습니다.",
    382: "그룹 참여 거절",
    383: "그룹 참여 신청이 거절되었습니다.",
    384: "신청서 입력",
    385: "사용 중인 그룹 이름입니다. 새로운 그룹 이름을 사용해 주세요.",
    386: "그룹 사진",
    387: "신청자 실명",
    388: "신청자 연락처",
    389: "소속",
    390: "그룹 참여\n승인 필요 여부",
    391: "필요",
    392: "불필요",
    393: "최대 50자까지 입력 가능합니다.",
    394: "그룹 이름은 2자 이상 입력해주세요.",
    395: "그룹 이름은 한글, 영문, 숫자, 띄어쓰기만 가능합니다.",
    396: "그룹 생성 신청",
    397: "<tag>{{%s}}</tag> 그룹 생성을 신청하시겠습니까?",
    398: "그룹 생성 신청 완료",
    399: "그룹 생성 신청을 위한 정보제출이 완료되었습니다.\n관리자의 승인이 필요하니 조금만 기다려주세요.",
    400: "신청서",
    401: "그룹 생성 신청 거절",
    402: "그룹 생성 신청이 거절되었습니다.",
    403: "그룹 생성 신청서",
    404: "전체",
    405: "요청 취소",
    406: "자료 사용권 요청 취소",
    407: "선택한 자료 사용권의 요청을 취소하시겠습니까?",
    408: "자료 사용권 요청 취소 완료",
    409: "자료 사용권 요청이 취소되었습니다.",
    410: "등록 자료",
    411: "자료 사용권 신청 거절",
    412: "자료 사용권 신청이 거절되었습니다.",
    413: "승인 대기",
    414: "승인 내역",
    415: "거부",
    416: "자료 사용권 신청 승인",
    417: "자료 사용권 신청을 승인하시겠습니까?",
    418: "자료 사용권 신청 거부",
    419: "아래와 같은 사유로 신청을 거부합니다.",
    420: "처리 날짜",
    421: "반려",
    422: "아래와 같은 사유로 신청을 거부하였습니다.",
    423: "등급",
    424: "기사단장",
    425: "수습기사",
    426: "기사",
    427: "수석기사",
    428: "그룹원 이름",
    429: "그룹원 등급",
    430: "가입일",
    431: "최종 방문일",
    432: "그룹 내 \n게시글 수",
    433: "그룹 내 \n댓글 수",
    434: "그룹원 등급 변경",
    435: "그룹원 탈퇴",
    436: "메일 발송",
    437: "전체 메일 발송",
    438: "변경 대상",
    439: "선택한 그룹원의 등급을 변경하시겠습니까?",
    440: "그룹원 등급이 변경되었습니다.",
    441: "탈퇴 사유",
    442: "불법광고 및 스팸 활동",
    443: "바람직하지 않은 활동",
    444: "운영 원칙 위배",
    445: "탈퇴 처리 하시겠습니까?",
    446: "그룹원 탈퇴 되었습니다.",
    447: "사유 :",
    448: "받는 사람",
    449: "메일 전송 완료",
    450: "메일이 정상적으로 전송되었습니다.",
    451: "메일 전송 실패",
    452: "메일 전송이 실패했습니다.",
    453: "그룹원 정보",
    454: "변경 내용 저장",
    455: "변경 내용을 저장하시겠습니까?",
    456: "그룹 선택 :",
    457: "수정 항목 :",
    458: "그룹원 등급 설정",
    459: "입력폼 변경",
    460: "그룹 소개 사진",
    461: "대표 매니저",
    462: "그룹원 등급명",
    463: "설명",
    464: "그룹 매니저",
    465: "삭제",
    466: "추가",
    467: "등급 명",
    468: "등급 삭제",
    469: "해당 등급을 삭제하시겠습니까?",
    470: "저장 완료",
    471: "저장이 완료되었습니다.",
    472: "저장 실패",
    473: "그룹원 등급명이 중복되어 저장에 실패했습니다.",
    474: "단답형",
    475: "단답형(숫자)",
    476: "장문형",
    477: "객관식 질문",
    478: "체크박스",
    479: "이미지 업로드",
    480: "조사 결과 입력",
    481: "질문에 대한 설명 (선택)",
    482: "필수",
    483: "입력폼을 변경하시겠습니까?",
    484: "입력폼이 변경되었습니다.",
    485: "수정 사항이 없습니다.",
    486: "그룹원 신청 승인",
    487: "그룹원 신청을 승인하시겠습니까?",
    488: "그룹원 신청 반려",
    489: "아래와 같은 사유로 신청을 반려합니다.",
    490: "아래와 같은 사유로 신청을 반려하였습니다.",
    491: "신청자",
    492: "회원 등급",
    493: "일반 회원",
    494: "회원 이름",
    495: "회원 정지",
    496: "정지 사유",
    497: "정지 처리 하시겠습니까?",
    498: "회원 정지 되었습니다.",
    499: "그룹 삭제",
    500: "다음과 같은 사유로 그룹을 삭제합니다.",
    501: "그룹 생성 승인",
    502: "그룹 생성을 승인하시겠습니까?",
    503: "그룹 생성 신청 반려",
    504: "오류",
    505: "예기치 못한 오류가 발생하였습니다.",
    506: "모바일에서는 사용이 불가합니다.\nPC에서 접속해주세요.",
    507: "아래의 형식에 맞춰 다시 파일을 첨부해 주세요.\n최대 첨부 가능 파일 개수 : {N}개\n최대 첨부 용량 : {N}{용량 단위}",
    508: "아래의 형식에 맞춰 다시 파일을 첨부해 주세요.\n최대 첨부 용량 : {N}{용량 단위}",
    509: "앱에서 보기",
    510: "괜찮아요. 모바일 웹에서 볼게요.",
    511: "사진{{%1$d}}: {{%2$d}}개",
    512: "삭제된 프로젝트",
    513: "원본 사진 다운",
    514: "입력 취소",
    515: "변경사항이 있습니다.\n저장하지 않고 종료하시겠습니까?",
    516: "쓰레기 종류",
    517: "개수",
    518: "합계",
    519: "조사자 :",
    520: "조사날짜 :",
    521: "그룹 설명이 없습니다.",
    522: "그룹에 대한 설명을 넣어주세요.",
    523: "현재 그룹에 소속되어 있지 않습니다.",
    524: "원하는 그룹에 참여해보세요!",
    525: "이미 종료된 프로젝트 입니다.",
    526: "베타테스트 버전입니다.",
    527: "자료 제목으로 등록할 수 없는 문구가 포함되어 있습니다.",
    528: "모두 비워지거나, 모든 값이 채워져야 합니다.",
    529: "선택된 project 가 없습니다.",
    530: "종료 시간이 더 늦게 설정되어야 합니다.",
    531: "사진",
    532: "쓰레기 종류별 \n개수 입력",
    533: "대한민국",
    534: "연관 project 가 설정되지 않았습니다.",
    535: "수정하는 중...",
    536: "제출하는 중...",
    537: "수정하기",
    538: "행정안전부에서 운영하는 <tag>안전신문고</tag>에 등록하시면 빠른 쓰레기 수거가 가능합니다.",
    539: "현재 권한으로는 접근할 수 없습니다.",
    540: "제출",
    541: "변경한 내용이 저장되었습니다.",
    542: "대표 매니저 선택",
    543: "그룹 참여\n승인 필요 여부",
    544: "옵션#",
    545: "옵션 추가",
    546: "또는",
    547: "기타 추가",
    548: "부분 입력",
    549: "구간# :",
    550: "단위",
    551: "옵션1",
    552: "사진 입력 개수",
    553: "아래의 형식에 맞춰 다시 파일을 첨부해 주세요.",
    554: "최대 첨부 가능 파일 개수 : {{%d}}개",
    555: "최대 첨부 용량 : {{%d}}MB",
    556: "파일 첨부 실패",
    557: "이미지 파일만 업로드 가능합니다.",
    558: "이제 이미지를 놓아주세요.",
    559: "이미지 드랍 or 클릭",
    560: "\"{{%s}}\"와(과) 같은 특정문자로 검색할 수 없습니다.",
    561: "<tag>{{%s}}</tag>에 대한 검색 결과가 없습니다.",
    562: "{{AA}}\n지번 : {{BB}} | {{DD}}",
    563: "주소 검색결과 # 건",
    564: "타이틀 영역",
    565: "회원 정보",
    566: "닫기",
    567: "그룹 중복확인이 필요합니다.",
    568: "사용할 수 있는 그룹 이름입니다.",
    569: "{{%s}} 그룹에서 탈퇴하시겠습니까?",
    570: "자진 탈퇴",
    571: "{{%s}} 그룹에서 탈퇴하였습니다.",
    572: "그룹 매니저({{%s}})",
    573: "일반 회원({{%s}})",
    574: "비밀번호를 입력하세요 (8자리 이상)",
    575: "비밀번호를 다시 입력하세요",
    576: "모달 닫기",
    577: "앱에서 보기",
    578: "Notice Email",
    579: "자료 사용권 승인 완료",
    580: "자료 사용권 신청이 승인되었습니다.",
    581: "이 메일 메시지는 자동으로 생성되었으므로 회신이 불가합니다.\n추가적인 도움이 필요하시면 <tag>오션 클라우드</tag> 홈페이지를 통해 문의해주세요",
    582: "그룹 생성 승인 완료",
    583: "그룹 생성 신청이 승인되었습니다.",
    584: "그룹 참여 신청 거절",
    585: "자료 사용권 신청",
    586: "자료 사용권이 신청되었습니다.",
    587: "그룹 생성이 신청되었습니다.",
    588: "그룹 참여가 신청되었습니다.",
    589: "Verify Email",
    590: "{{%s}} 되었습니다.",
    591: "오전",
    592: "오후",
    593: "모델 로드 도중 오류 발생",
    594: "어번 나이츠",
    595: "URBAN",
    596: "Knights",
    597: "custom",
    598: "라벨 제거",
    599: "해당 라벨을 제거 하시겠습니까?",
    600: "쓰레기를 드래그하여\n라벨링을 진행해 주세요.",
    601: "다시 보지 않기",
    602: "쓰레기 종류를\n선택해 주세요.",
    603: "다시 시도",
    604: "각도를 지면과 수평으로\n유지한 후 촬영해 주세요.",
    605: "오션 클라우드의 계정으로\n로그인 가능합니다.\n\n회원가입이나 비밀번호 찾기는\n오션 클라우드를 이용해주세요.",
    606: "오션 클라우드",
    607: "로그인 불가",
    608: "어번 나이츠 전용 앱입니다.\n어번 나이츠에 가입 후 이용해 주세요.",
    609: "네트워크 인식 불가",
    610: "네트워크가 연결되어 있지 않습니다.\n네트워크 연결 후 다시 시도해 주세요.",
    611: "권한 거부됨",
    612: "카메라",
    613: "이미지 미디어 읽기",
    614: "저장소 읽기",
    615: "저장소 쓰기",
    616: "미디어 위치 확인",
    617: "자세한 위치",
    618: "인터넷",
    619: "네트워크 상태 확인",
    620: "사용 가이드",
    621: "앱 종료",
    622: "필수 권한 허용 안내",
    623: "{{%1$s}}를 이용하기 위해서는 {{%2$s}} 사용 권한 허용이 반드시 필요합니다.",
    624: "권한 설정",
    625: "앱 리소스 다운로드",
    626: "{{%1$s}}를 이용하기 위해서는 앱 리소스 다운로드가 반드시 필요합니다.\n\n앱 리소스를 다운로드 하시겠습니까?\n\n리소스 용량 : {{%2$s}}",
    627: "다운로드",
    628: "Downloading",
    629: "※ 추후 쓰레기 항목 추가 예정",
    630: "파일 업로드 중입니다.\n업로드가 완료된 후 다시 시도해 주세요.",
    631: "연도-월-일",
    632: "-- --:--",
    633: "임시 저장된 자료가 있습니다.\n이어서 작성하시겠습니까?",
    634: "자료 삭제",
    635: "임시 저장",
    636: "자료가 임시 저장되었습니다.",
    637: "자료 제출하기를 통해 이어서 작성하실 수 있습니다.",
    638: "자료가 영구적으로 삭제됩니다.",
    639: "그래도 삭제하시겠습니까?",
    640: "‘뒤로’ 버튼을 한번 더 누르시면 앱이 종료됩니다.",
    641: "사진 촬영 실패",
    642: "카메라가 흔들려 사진 촬영에 실패했습니다.\n다시 시도해 주세요.",
    643: "https://www.safetyreport.go.kr/#safereport/safereport2",
    644: "공동 조사자",
    645: "AI 모델 클래스",
    646: "특수문자를 입력 할 수 없습니다.",
    647: "프로젝트 생성 실패",
    648: "프로젝트 생성 권한이 없습니다.",
    649: "그룹 매니저에게 문의해 주세요.",
    650: "임시 저장된 자료가 있습니다.",
    651: "이어서 작성하시겠습니까?",
    652: "타이틀",
    653: "웹으로 보기",
    654: "카카오",
    655: "링크 시간이 만료되었습니다.",
    656: "자료명",
    657: "자료활용 공익단체 신청서",
    658: "앱에서 바로 촬영 시 스마트폰에 사진이 저장되지 않습니다.\n스마트폰에 사진 저장을 원할 경우, 바다기사단 앱이 아닌 스마트폰 카메라로 먼저 사진을 찍으신 후 사진 가져오기를 통해 자료를 입력해 주세요.",
    659: "제거",
    660: "페이스북 계정으로 가입",
    661: "카카오 계정으로 가입",
    662: "자주 묻는 질문",
    663: "질문",
    664: "답변",
    665: "그룹 명",
    666: "자료활용 공익단체 승인",
    667: "자료활용 공익단체를 승인하시겠습니까?",
    668: "자료활용 공익단체 신청 반려",
    669: "자료활용 공익단체 권한 삭제",
    670: "아래와 같은 사유로 권한을 삭제합니다.",
    671: "자료활용 공익단체",
    672: "수정 항목",
    673: "알림 정보를 획득하지 못했습니다.",
    674: "요청 대기",
    675: "그룹 생성 신청 승인 완료",
    676: "자료활용 공익단체 신청",
    677: "자료활용 공익단체를 신청하시겠습니까?",
    678: "자료활용 공익단체 신청 완료",
    679: "자료활용 공익단체 신청을 위한 정보제출이 완료되었습니다.\n그룹 매니저의 승인이 필요하니 조금만 기다려주세요.",
    680: "자료활용 공익단체 신청 거절",
    681: "자료활용 공익단체 신청이 거절되었습니다.",
    682: "자료활용 공익단체는 교육, 연구 등 비영리 목적으로, 신청한 그룹의 모든 자료를 다운로드하여 활용할 수 있습니다.",
    683: "신청할 그룹이 없습니다.",
    684: "신청할 그룹",
    685: "직급",
    686: "소속 증명",
    687: "프로젝트\n생성권한",
    688: "유저 등급",
    689: "다운로드 가능한 자료가 없습니다.",
    690: "해당되는 자료를 찾을 수 없습니다.",
    691: "{{%1$d}} 년 {{%2$d}} 월",
    692: "전체 수집 자료 건수",
    693: "지역별 수집 자료 건수",
    694: "그룹별 수집 자료 건수",
    695: "그룹이 삭제되었습니다.",
    696: "자료활용 공익단체 신청이 승인되었습니다.",
    697: "회원 탈퇴 되었습니다.",
    698: "이메일 인증 확인",
    699: "조사 개수 버튼 입력",
    700: "그래프 유형 :",
    701: "그래프 선택",
    702: "원형 그래프",
    703: "도넛형 그래프",
    704: "가로 막대형 그래프",
    705: "세로 막대형 그래프",
    706: "자료 제목",
    707: "시작 시간",
    708: "종료 시간",
    709: "※ 스마트폰으로 촬영하기 전 설정에서 '위치태그' 를 추가하여 활영한 장소를 알 수 있게 합니다.",
    710: "수동입력",
    711: "검색 태그(선택)",
    712: "음료수병(플라스틱)",
    713: "비닐 봉지(일회용 쇼핑백)",
    714: "식품 용기(스티로폼, 도시락 등)",
    715: "음식 포장(과자 라면 봉지)",
    716: "담배 꽁초",
    717: "폭죽(불꽃놀이 용품)",
    718: "밧줄",
    719: "통발",
    720: "낚싯줄(납추, 낚싯바늘 붙은것 포함)",
    721: "닉네임을 2자 이상 입력해 주세요.",
    722: "구간",
    723: "주요 쓰레기 종류를 선택합니다",
    724: "주요 쓰레기의 노후 상태를 기록합니다",
    725: "스마트폰으로 촬영 전 카메라 설정에서 '위치태그'를 추가하였나요?",
    726: "스마트폰으로 촬영하기 전 설정에서 ‘위치태그＇를 추가하여 촬영한 장소를 알 수 있게 합니다.",
    727: "예",
    728: "해안 100m를 10m씩 나눠, 한 구간씩 이동하면서 10m 구간에 있는 쓰레기의 부피를 어림하여 숫자만 입력합니다. 숫자입력이 끝나면 되돌아 오면서 사진을 1장씩 찍습니다.",
    729: "자료 활용 동의서",
    730: "\n자료의 저작권(고유권한)은 제출자에게 있습니다. 제출한 자료는 공공 목적으로만 활용 합니다.\n\n제출한 사진, 영상 등의 저작권을 바다기사단이 공공 목적에 사용하는 데 동의합니다.\n    - 사용매체 : 카페, 홈페이지, 페이스북, 인스타그램, 유튜브 등의 SNS 및 신문, 잡지, 방송매체 등\n    - 사용목적 : \n            1) 해양쓰레기의 양과 분포를 파악하는데 사용합니다.\n            2) 양과 분포에 따라 해양쓰레기 관리가 시급히 필요한 곳을 파악합니다.\n            3) 수집한 자료를 이용한 홍보물과 교육자료, 정책 자료를 제작 배포 합니다.\n            4) 장기간에 걸친 자료를 축적하여 쓰레기가 실제로 줄어드는지 평가하는데 사용합니다.\n            5) 활동 결과 공유, 공익 캠페인 전개, 작품 전시 등\n    - 사용자 : 바다기사단, 바다기사단에서 승인한 협력기관\n\n저작자의 별도 명시가 없을 경우 저작물의 DB 구축과 인터넷을 포함한 정보통신망을 통해 배포 및 전송, 재가공을 허락합니다. 위에 열거된 사용매체, 사용목적, 사용자 외에 저작물을 사용 시 원작자와 사전 협의 후 진행될 수 있습니다.        \n",
    731: "구간 1",
    732: "(단위: 리터/10m 해안길이)",
    733: "구간 2",
    734: "구간 3",
    735: "구간 4",
    736: "구간 5",
    737: "다이빙 상태 (수심 m)",
    738: "다이빙 상태 (수온 ℃)",
    739: "다이빙 상태 (시야 m)",
    740: "대형 투기 쓰레기 (가전제품, 타이어 등",
    741: "구간 6",
    742: "구간 7",
    743: "구간 8",
    744: "구간 9",
    745: "구간 10",
    746: "다이빙 상태",
    747: "수심 :",
    748: "수온 :",
    749: "시야(특정 물체를 정확히 확인할 수 있는 거리) :",
    750: "시작점(위도,경도)",
    751: "위도, 경도 :",
    752: "100m 구간폭 :",
    753: "스마트폰으로 촬영 전 카메라 설정에서 '위치태그' 를 추가하였나요?",
    754: "조사 대상지",
    755: "배수구",
    756: "길가",
    757: "공터",
    758: "하천변",
    759: "조사 면적(㎡)",
    760: "조사 결과",
    761: "음식 포장(과자, 라면 봉지)",
    762: "해수욕장",
    763: "쓰레기 조사 결과",
    764: "폭죽 화약피(플라스틱)",
    765: "폭죽 와이어(금속)",
    766: "종이포장 등 기타",
    767: "피해 조사 결과",
    768: "폭죽을 터트리는 행위 또는 방치된 폭죽 쓰레기로 인해 피해 입은 경험이 있는지 조사하여 기록해 주세요.",
    769: "폭죽쓰레기 근절 방안",
    770: "폭죽쓰레기 근절을 위해 할 수 있는 조처에는 무엇이 있을까요?",
    771: "폭죽 판매 단속(폭죽을 판매하는 것은 법에 불법으로 규정하고 있지 않음)",
    772: "폭죽 구매와 사용 단속(구매와 사용은 불법)",
    773: "바닷가 인근 시설에 홍보 게시판 설치",
    774: "폭죽쓰레기 수거를 위한 수거함 설치",
    775: "폭죽 판매를 막기 위한 법률 개선",
    776: "광범위 대중 캠페인",
    777: "대중홍보용 사진 공모",
    778: "대중들에게 폭죽 쓰레기 근절을 알릴 홍보물에 사용할 수 있는 사진을 공모합니다. 멋진 사진을 많이 올려 주세요(제출 기한 1차: 2023. 11. 31)",
    779: "바닷가에서 발견한 폭죽 쓰레기 사진을 찍고 사진 속 쓰레기의 종류와 갯수를 기록합니다. 기록 후 폭죽 쓰레기를 수거합니다.",
    780: "폭죽 화약피(담배꽁초 크기 진회색 플라스틱 튜브형태)",
    781: "폭죽 와이어(약 20cm 길이의 철사)",
    782: "종이포장 등 기타(막대 또는 정육면체 모양의 포장)",
    783: "폭죽 쓰레기 근절 방안",
    784: "폭죽 쓰레기 근절을 위해 어떤 방법을 가장 먼저 해야 할까요(3개만 선택)?",
    785: "조사 대상지의 이름을 적어 주세요(OOO해수욕장, OO방파제 등).",
    786: "방파제",
    787: "해수욕장이 아닌 해변/숙박시설",
    788: "폭죽 쓰레기 조사 결과",
    789: "바닷가에서 발견한 폭죽 쓰레기 사진을 찍고 사진 속 쓰레기의 종류와 갯수를 기록합니다. 기록 후 폭죽 쓰레기를 수거합니다. (사진은 최대 20장까지 올릴 수 있습니다)",
    790: "폭죽 쓰레기 근절을 위해 어떤 방법을 가장 먼저 해야 할까요(3개만 선택)? (1개만 선택되는 상황입니다. 관리자에게 요청하겠습니다)",
    791: "대중홍보용 사진 공모전",
    792: "대중들에게 폭죽 쓰레기 근절을 알릴 홍보물에 사용할 수 있는 사진을 공모합니다. 멋진 사진을 많이 올려 주세요. 10장까지 올릴 수 있습니다. 공모전 시상 내역은 오션 홈페이지를 참고해 주세요. 시상 후 저작권을 표시하여 홍보에 사용합니다(제출 기한 1차: 2023. 10. 31). (www.osean.net 참고)",
    793: "큰 길가",
    794: "골목",
    795: "상가 주변",
    796: "플라스틱 음료수병_생수",
    797: "플라스틱 음료수병_생수 제외한 음료",
    798: "스티로폼 용기(컵라면, 식재료 포장, 과일 포장 등)",
    799: "비닐 음식 포장 비닐(과자, 라면 봉지)",
    800: "비닐봉지(일회용, 테이크아웃 포장 등)",
    801: "플라스틱 테이크아웃 컵",
    802: "종이 테이크아웃 컵",
    803: "종이포장재(쇼핑백, 택배 상자 등)",
    804: "물휴지",
    805: "폭죽 쓰레기 근절 방안 - 주관식",
    806: "폭죽 쓰레기 근절을 위해 어떤 방법을 가장 먼저 해야 할까요? 자유롭게 의견을 주시기 바랍니다.",
    807: "대중홍보용 사진 공모전(11. 30 마감)",
    808: "대중들에게 폭죽 쓰레기 근절을 알릴 홍보물에 사용할 수 있는 사진을 공모합니다. 멋진 사진을 많이 올려 주세요. 10장까지 올릴 수 있습니다. 공모전 시상 내역은 오션 홈페이지를 참고해 주세요. 시상 후 저작권을 표시하여 홍보에 사용합니다(제출 기한 1차: 2023. 11. 31). (www.osean.net 참고)",
    809: "폭죽 쓰레기 근절을 위해 어떤 방법을 가장 먼저 해야 할까요? (3개만 선택)",
    810: "대중홍보용 사진 모집",
    811: "대중들에게 폭죽 쓰레기 근절을 알릴 홍보물에 사용할 수 있는 사진을 모집합니다.",
    812: "바닷가에서 발견한 폭죽 쓰레기 사진을 찍고 사진 속 쓰레기의 종류와 갯수를 기록합니다. 기록 후 폭죽 쓰레기를 수거합니다. (사진 속에 있는 것만 기록합니다. 최대 20장까지 올릴 수 있습니다)",
    813: "폭죽 쓰레기 근절을 위해 어떤 방법을 가장 먼저 시도해야 할까요? (3개만 선택)",
    814: "대중들에게 폭죽 쓰레기 근절을 알릴 홍보물에 사용할 수 있는 사진을 모집합니다. 10장까지 올릴 수 있습니다. 오션 홈페이지에서 사진 컨테스트에 대한 정보를 확인하세요. www.osean.net",
    815: "하천/강변",
    816: "조사 면적(㎡)(선택)",
    817: "플라스틱 음료수병과 뚜껑(먹는 물)",
    818: "플라스틱 음료수병과 뚜껑(각종 음료)",
    819: "스티로폼 포장 용기(라면, 식재료, 과일 등)",
    820: "플라스틱 포장 용기(식재료, 과자 등)",
    821: "플라스틱 테이크아웃 컵과 빨대",
    822: "종이 테이크아웃 컵과 종이빨대",
    823: "어떤 쓰레기가 가장 많았나요?",
    824: "쓰레기를 근본적으로 줄이기 위해 지자체, 정부에 제안하고 싶은 아이디어, 개인이 실천할 수 있는 슬기로운 방법이있으면 자유롭게 작성해 주세요.",
    825: "※ + 기호를 누르면 사진을 찍거나 갤러리의 사진을 선택하여 올릴 수 있습니다. 사진을 올리고 아래 입력 버튼을 눌러 사진 속에 있는 폭죽 쓰레기의 종류와 개수를 기록합니다. (사진 속에 있는 것만 기록합니다. 최대 20장까지 올릴 수 있습니다.)",
    826: "청소 전 한눈에 봤을 때 어떤 쓰레기가 많이 보이나요?",
    827: "위 쓰레기는 주로 어디에서 온 것일까요?",
    828: "해안의 주변에 어떤 시설이 많나요?",
    829: "상업 및 공업 지역",
    830: "이곳은 청소 후 수거한 쓰레기를 운반하기 쉽나요?",
    831: "열일캠페인의 10가지 항목 조사",
    832: "우리나라 바다에서 가장 양이 많고 피해가 심각한 10가지 항목을 중심으로 조사해 보세요. 10가지를 집중해서청소하면 우리 바다를 보다 효율적으로 깨끗하고 안전하게 만들 수 있습니다.",
    833: "어업용 밧줄",
    834: "페트병(병과 뚜껑, 음료용)",
    835: "식품포장용 비닐(커피포장, 라면봉지, 과자봉지 등)",
    836: "비닐봉지",
    837: "낚시쓰레기(낚싯줄, 바늘, 추, 천평, 가짜미끼, 낚시용품 비닐포장 등)",
    838: "폭죽쓰레기(연발 폭죽 화약피, 스파클러 철사, 로망캔들 종이막대 등)",
    839: "플라스틱 노끈(양식업용 또는 포장용)",
    840: "장어통발",
    841: "10가지 항목을 포함해서 수거한 쓰레기의 총량",
    842: "열일캠페인의 항목 10가지 이외의 쓰레기는 기록하지 않고 모두 청소합니다. 청소 후 수거한 양을 기록해 보세요(50리터 쓰레기 봉투 기준).",
    843: "개(봉투)",
    844: "#열일캠페인#스티로폼부표#밧줄#페트병#식품포장비닐#비닐봉지#낚시#담배꽁초#노끈#통발",
    845: "쓰레기를 근본적으로 줄이기 위해 지자체, 정부에 제안하고 싶은 아이디어, 기업이 할 수 있는 일, 개인이 실천할 수있는 일 등 자유롭게 작성해 주세요",
    846: "바다에게 전하고 싶은 말",
    847: "열일캠페인의 10가지 항목 조사-->항목별 수거량을 기입하도록 수정해야 함",
    848: "열일캠페인의 10가지 항목 조사-->항목별 수거량을 + - 터치할 때마다 숫자가 올라가고 내려갈 수 있게. 쓰레기 자동합계 기능",
    849: "10가지 항목을 포함해서 수거한 쓰레기의 총량. 봉투 개수와 무게 기록할 수 있게. 봉투 20리터 기준 갯수(개), 무게(kg)",
    850: "열일캠페인의 항목 10가지 이외의 쓰레기는 기록하지 않고 모두 청소합니다. 청소 후 수거한 양을 기록해 보세요(20리터 쓰레기 봉투 기준). (20리터 강조, 굵게)",
    851: "남기고 싶은 말",
    852: "쓰레기를 근본적으로 줄이기 위해 지자체, 정부에 제안하고 싶은 아이디어, 기업이 할 수 있는 일, 개인이 실천할 수 있는 일 등 자유롭게 작성해 주세요.",
    853: "깨끗해진 바닷가 사진을 찍어서 올려 주세요.",
    854: "열일캠페인의 10가지 항목 조사-->항목별 수거량을 +- 터치할 때마다 숫자가 올라가고 내려갈 수 있게 수정 예정 쓰레기 자동합계 기능",
    855: "특이한 쓰레기 사진",
    856: "조사한 10가지 항목 외에 특이한 쓰레기, 기록해 두고 싶은 쓰레기가 있으면 사진을 올려 주세요.",
    857: "수거한 쓰레기 봉투수와 무게",
    858: "당신의 헌신으로 바다가 깨끗해졌습니다. 인증샷을 찍어 주세요.",
    859: "바닷가 사진, 활동 사진 등 자유롭게 올려 주세요.",
    860: "우리나라 바다에서 가장 양이 많고 피해가 심각한 10가지 항목을 중심으로 조사해 보세요. 10가지를 집중해서청소하면 우리 바다를 보다 효율적으로 깨끗하고 안전하게 만들 수 있습니다.",
    861: "Other",
    862: "수거한 쓰레기 봉투수",
    863: "열일캠페인의 항목 10가지 이외의 쓰레기는 기록하지 않고 모두 청소합니다. 청소 후 수거한 양을 기록해 보세요(20리터 쓰레기 봉투 기준).",
    864: "특이한 쓰레기 사진(3장까지 가능)",
    865: "당신의 헌신으로 바다가 깨끗해졌습니다. 인증샷을 찍어 주세요(5장까지 가능).",
    866: "사진{{%d}} [파일 이름]",
    867: "사진{{%d}} [합계]",
    868: "[구간 합]",
    869: "[구간별 파일]",
    870: "(단위: {{%s}})",
    871: "수량 합계 : {{%d}}",
    872: "세종특별자치시",
    873: "이미지 첨부 실패",
    874: "[{{%s}}] 이미지의 해상도가 너무 높습니다.\n8K 이하의 해상도로 변환하여 업로드 해주세요.",
    875: "사이트 관리",
    876: "객체 인식 AI 모델",
    877: "미설정",
    878: "AI 모델 등록",
    879: "AI 모델 목록",
    880: "모델 버전",
    881: "클래스 목록",
    882: "모델 파일",
    883: "모델 타입",
    884: "모델 설명",
    885: "※ class 목록은 `,` 구분자로 나눠 입력해 주세요. ex) 나무, 지우개",
    886: "모델 설명을 입력해 주세요 (선택 사항)",
    887: "등록하기",
    888: "모델 타입 (예> yolov4, yolov7, ...)",
    889: "기능 확인",
    890: "Apple로 로그인",
    891: "Google로 로그인",
    892: "[소셜명] 계정으로 회원 가입이 되어 있지 않은 상태입니다.",
    893: "[소셜명] 계정으로 로그인 할 수 없습니다.",
    894: "Apple로 등록",
    895: "Google로 등록",
    896: "실명",
    897: "실제 이름을 입력하세요.",
    898: "다시 찍기",
    899: "개수 입력",
    904: "회원 정보 입력",
    905: "동의하기",
    906: "오션 클라우드를 계속 사용하시려면 새롭게 변경된 약관에 동의 후 회원 정보를 다시 입력해 주세요.",
    907: "변경사항 : 실명 수집",
    908: "실명을 입력하세요.",
    911: "실명은 한글, 영문, 띄어쓰기만 가능합니다.",
    912: "실명은 2자 이상 입력해 주세요.",
};

const i18nGroupName = (group_name, lang) => {
    return (group_name == "어번 나이츠" && (lang && lang != 'ko' && !lang.startsWith("ko-"))) ? en['594'] : group_name;
};

module.exports = {
    helper: {
        i18nGroupName
    },
    en,
    ko
};
